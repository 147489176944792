import Container from '../components/container'
import Header from '../components/header'
import Pages from '../enums/pages'
import Background, { Raised } from '../components/background';
import Wrapper from '../components/centered-wrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import styled from '@emotion/styled'
import { Component } from 'react'
import Footer from '../components/footer'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import ReactGA from '../components/ga'

const BrandContainer = styled.div`
    text-align: center;
    z-index: 5;
    color: rgba(255,255,255,0.7);
`
const IconContainer = styled.div`
    & a {
        margin: 5px 10px;
        opacity: 0;
        top: -5px;
        transition: top 0.15s, opacity 0.15s;
        position: relative;
        color: rgba(255,255,255,0.7);
    }
    ${(props: { children: JSX.Element[] }) => props.children.map((c, i) => `& a:nth-of-type(${i + 1}) {
        transition-delay: ${0.2 * (i + 1)}s;
    }`)}
    & a:hover {
        color: white;
    }
`

class IconContainerComponent extends Component<any, { mounted: boolean }> {
    constructor(props) {
        super(props)
        this.state = {
            mounted: false
        }
    }

    send = evt => {
        evt.preventDefault();
        window.location.href = 'mailto:jason[$]jasonbgao.com'.replace("[$]", "@")
    }

    componentDidMount() {
        setTimeout(() => this.setState({ mounted: true }), 50)
    }

    render () {
        let style = this.state.mounted ? { top: 0, opacity: 1 } : {};
        return (
            <IconContainer>
                <a href='#' onClick={this.send} target='_blank' style={style}>
                    <FontAwesomeIcon icon={faEnvelope} size='2x' />
                </a>
                <a href='https://github.com/jasong689' target='_blank' style={style}>
                    <FontAwesomeIcon icon={faGithub} size='2x' />
                </a>
                <a href='https://www.instagram.com/jj689/' target='_blank' style={style}>
                    <FontAwesomeIcon icon={faInstagram} size='2x' />
                </a>
                <a href='https://www.linkedin.com/in/jason-gao-60112a41' target='_blank' style={style}>
                    <FontAwesomeIcon icon={faLinkedin} size='2x' />
                </a>
            </IconContainer>
        )
    }
}

const Contact = () => (
    <Container>
        <Header page={Pages.Contact} />
        <Wrapper>
            <Background raised={Raised.Left} offset={0} />
            <BrandContainer>
                <div style={{ marginBottom: '5px', fontWeight: 400, textAlign: 'center', fontSize: '1.2em' }}>
                    Contact/Follow Me:
                </div>
                <IconContainerComponent />
            </BrandContainer>
        </Wrapper>
        <Footer />
    </Container>
)

const ContactGa = ({ location }) => <ReactGA pathname={location.pathname}><Contact /></ReactGA>

export default ContactGa